import { useEffect } from "react";
import { useChainContext } from "../contexts/ChainProvider";

const NATIVE_DENOMS = {
    uluna: { symbol: 'LUNC', decimals: 6 },
    uusd: { symbol: 'USTC', decimals: 6 },
};

const fetchedDenomsCache = {};

export const useDenomInfo = (denom, chainId) => {
    const { denomData, setDenomData, chainClients } = useChainContext();
    
    const fetchCW20Info = async (denom, chainId) => {
        const cacheKey = `${chainId}_${denom}`;
        
        if (fetchedDenomsCache[cacheKey]) {
            return;
        }
        
        fetchedDenomsCache[cacheKey] = true;

        chainClients[chainId].getTokenInfo(denom).then((data) => {
            console.log('fetchCW20Info', data);
            if (data && data.symbol) {
                setDenomData(prevDenomData => ({
                    ...prevDenomData,
                    [chainId]: {
                        ...(prevDenomData[chainId] || {}),
                        [denom]: { symbol: data.symbol, decimals: data.decimals }
                    }
                }));
            }    
        }).catch((err) => {
            // handle error or ignore
            console.log('fetchCW20Info err', err);
            setDenomData(prevDenomData => ({
                ...prevDenomData,
                [chainId]: {
                    ...(prevDenomData[chainId] || {}),
                    [denom]: { symbol: denom, decimals: 6 }
                }
            }));
        }).finally(() => {
            fetchedDenomsCache[cacheKey] = false;
        });
    };

    console.log('useDenomInfo', denom, chainId);

    useEffect(() => {
        if (NATIVE_DENOMS[denom]) return; // No need to fetch native denoms

        if (denom && chainId && !denomData[chainId]?.[denom] && !fetchedDenomsCache[`${chainId}_${denom}`]) {
            console.log('useDenomInfo fetchCW20Info', denom);

            fetchCW20Info(denom, chainId);
        }
    }, [denom, chainId]);

    if(!chainId || !denom) {
        console.log('useDenomInfo no chainId', denom);
        return null;
    } else if (NATIVE_DENOMS[denom]) {
        console.log('useDenomInfo NATIVE_DENOMS', denom, NATIVE_DENOMS[denom]);
        return NATIVE_DENOMS[denom];
    } else if (denomData[chainId] && denomData[chainId][denom]) {
        console.log('useDenomInfo denomData', denom, denomData[chainId][denom]);
        return denomData[chainId][denom];
    } else {
        console.log('useDenomInfo cw20 missing', denom);
        // fetch and store CW20 info
        return null;
    }
};
