import { IconButton, Grid, TextField, Select, MenuItem } from "@mui/material";
import { Field, useFormikContext } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormikTextField from "./FormikTextField";
import { DateTimePicker } from "@mui/x-date-pickers";

const VestingSlotsList = ({ fieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  if (!values.vesting) {
    return null;
  }

  return (
    <>
      {values.vestingSettings.slots.map((item, index) => (
        <Grid container spacing={3} key={index}>
          <Grid item xs={12}>
            <FormikTextField
              variant="standard"
              fullWidth
              value={values.vestingSettings.slots[index].identifier}
              name={`vestingSettings.slots[${index}].identifier`}
              label="Identifier"
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.vestingSettings.slots[index].apr}
              name={`vestingSettings.slots[${index}].apr`}
              label="APR"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.vestingSettings.slots[index].aprAfterRelease}
              name={`vestingSettings.slots[${index}].aprAfterRelease`}
              label="APR After Release"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>

          <Grid item xs={4}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.vestingSettings.slots[index].releasePeriod}
              name={`vestingSettings.slots[${index}].releasePeriod`}
              label="Release Period"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={5}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Earliest Release Time"
              value={values.vestingSettings.slots[index].releaseStartTime}
              onChange={(value) => {
                const slots = [...values.vestingSettings.slots];
                slots[index].releaseStartTime = value;
                setFieldValue('vestingSettings.slots', slots);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <Field
              name={`vestingSettings.slots[${index}].releaseType`}
              as={Select}
              label="Release Type"
              fullWidth
            >
              <MenuItem value="linear">Linear</MenuItem>
              <MenuItem value="fixed">Fixed</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => {
              const list = [...values.vestingSettings.slots];
              list.splice(index, 1);
              setFieldValue('vestingSettings.slots', list);
            }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => {
          setFieldValue('vestingSettings.slots', [...values.vestingSettings.slots, { identifier: '', apr: '', aprAfterRelease: '', releaseStartTime: '', releasePeriod: '', releaseType: 'linear' }]);
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </>
  );
}

export default VestingSlotsList;
