import React from 'react';
import { useFormikContext, Field } from 'formik';
import { Grid, MenuItem, Select, TextField } from "@mui/material";
import { NumericFormat } from 'react-number-format';

const CPFeesFields = () => {
  const { touched, values, errors, setFieldValue } = useFormikContext();

  return (
    <>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} sm={6}>
          <Field 
            name="launchFees.amount"
            type="number"
            label="Launch Fees Amount"
            inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
          >{({ field }) => (
            <NumericFormat
              {...field}
              thousandSeparator=","
              decimalScale={0}
              fixedDecimalScale
              allowNegative={false}
              label="Launch Fees Amount"
              error={touched.launchFees?.amount && Boolean(errors.launchFees?.amount)}
              helperText={touched.launchFees?.amount && errors.launchFees?.amount}
                prefix=""
                onValueChange={(values) => { setFieldValue('launchFees.amount', values.value); }}
                customInput={TextField}
            />
          )}</Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="launchFees.token"
            as={Select}
            label="Launch Fees Token"
            fullWidth
            value={values.launchFees?.denom}
          >
            <MenuItem value="uluna">LUNC</MenuItem>
            <MenuItem value="uusd">USTC</MenuItem>
          </Field>
        </Grid>
      </Grid>
    </>
  );
};

export default CPFeesFields;
