import { Box } from '@mui/material';
import MKProgress from 'components/MKProgress';

const ProgressBar = ({ progress, color, label }) => {
  return (
      <Box width="100%" mt={1}>
        <MKProgress variant="gradient" label={true} labelText={label} value={progress} style={{ backgroundColor: color, height: "20px" }} />
      </Box>
  );
};

export default ProgressBar;
