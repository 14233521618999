/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import PresaleForm from "components/TokenFactory/PresaleForm";
import { useEffect, useState } from "react";
import { useChainContext } from "contexts/ChainProvider";
import MKTypography from "components/MKTypography";
import ProgressBar from "components/Progressbar/Progressbar";
import { useParams } from "react-router-dom";
import { useDenomInfo } from "hooks/useDenomInfo";
import { Box } from "@mui/material";
import { FACTORY_CONTRACT } from "config/constant";
import MKButton from "components/MKButton";
import VestingSlotBox from "components/TokenFactory/VestingSlotBox";

function Vesting() {

  const { token: tokenContract } = useParams();

  const [conversionRate, setConversionRate] = useState(0);
  //const [progress, setProgress] = useState({ phase1: 0, phase2: 0, phase3: 0, tokens_total_1: 0, tokens_sold_1: 0, tokens_total_2: 0, tokens_sold_2: 0, tokens_total_3: 0, tokens_sold_3: 0 });
  const [vesting, setVesting] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [vestingSlots, setVestingSlots] = useState([]);
  const [vestingContract, setVestingContract] = useState(null);
  const [claimable, setClaimable] = useState(0);

  const { chainClients, chainId, setBroadcasting, handleError } = useChainContext();

  const denomData = useDenomInfo(tokenContract, chainId);
  
  const handleClaim = async (ev) => {
    ev.preventDefault();
    
    console.log('claim');

    setBroadcasting(true);
    chainClients[chainId].vestingClaim(vestingContract).then((data) => {
        alert('Claimed');
    }).catch((err) => {
        // handle error or ignore
        handleError(err);
    }).finally(() => {
        setBroadcasting(false);
        setReloadData(!reloadData);
    });
        
    console.log('Claiming Tokens');
  };

  const formatDenom = (amount) => {
    return (amount / Math.pow(10, denomData.decimals)).toLocaleString(undefined, {maximumFractionDigits: denomData.decimals});
  }

  useEffect(() => {
    if(!tokenContract || !chainClients || !chainClients[chainId]) {
      return;
    }

    chainClients[chainId].getFactoryTokenInfo(FACTORY_CONTRACT, tokenContract).then((res) => {
      console.log(res);
      if(!res?.settings?.vesting?.contract) {
        return;
      }

      setVestingContract(res.settings.vesting.contract);
    });
  }, [chainClients, chainId, tokenContract]);

  useEffect(() => {
    console.log('useEffect', chainId, vestingContract, chainClients)
    if(!vestingContract || !chainClients || !chainClients[chainId]) {
      return;
    }

    chainClients[chainId].getConfig(vestingContract).then((res) => {
      console.log(res);
      if(!res || !res.slots) {
        return;
      }

      let slots = [];
      for(const slot in res.slots) {
        slots.push({
          ...res.slots[slot],
          identifier: slot
        });
      }

      console.log('slots are', slots);
      
      setVestingSlots(slots.sort((a, b) => a.release_period - b.release_period));
    });

    if(chainClients[chainId].wallet) {
      console.log('getVestingHolderInfo', chainClients[chainId].wallet);
      chainClients[chainId].getVestingHolderInfo(vestingContract, chainClients[chainId].wallet.walletAddress).then((res) => {
        console.log(res);
        if(!res || !res.vesting) {
          return;
        }
        
        console.log('user vesting', res);
        setVesting(res.vesting);
        setClaimable(res.vested_tokens)
      });
    }
  }, [chainClients, chainId, reloadData, vestingContract]);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
        />
      </MKBox>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {(vestingSlots || []).map((slot, index) => (
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={4}
            xl={3}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
            key={slot.identifier}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 2, sm: 2, md: 2 }}
              mx={3}
              p={3}
            >
              <VestingSlotBox slot={slot} />
            </MKBox>
          </Grid>
        ))}
      
        {(vesting?.vestings?.length > 0) && (
           <Grid
           item
           xs={12}
           sm={10}
           md={6}
           lg={4}
           xl={3}
           ml={{ xs: "auto", lg: 6 }}
           mr={{ xs: "auto", lg: 6 }}
         >
          <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 2, sm: 2, md: 2 }}
              mx={3}
              p={3}
            >
              <MKTypography variant="h6" color="secondary">
                Your tokens
              </MKTypography>
              {(vesting.vestings.map((vesting) => {
                return (
                  <>
                  <MKTypography variant="body2" color="secondary" key={vesting.vesting_time}>
                    Locked tokens: {formatDenom(vesting.amount)} {denomData?.symbol}<br />
                    Claimed tokens: {formatDenom(vesting.released)} {denomData?.symbol}<br />
                    Release period: {vesting.slot.release_period === 0 ? 'immediate' : `${vesting.slot.release_period} day${(vesting.slot.release_period !== 1 ? 's' : '')} (${vesting.slot.release_type === 'fixed' ? 'Fixed' : 'Linear'})`}<br />
        {(vesting.slot.release_starts_after > 0) && (
          <>
          Earliest release date: {(new Date(vesting.slot.release_starts_after/1000000)).toLocaleDateString()}
          </>
        )}
                  </MKTypography>
                  <hr style={{margin: '16px 0'}} />
                  </>
                );
              }))}
              <MKTypography variant="body2" color="secondary" mb={2}>
                Claimable tokens: {formatDenom(claimable)} {denomData?.symbol}
              </MKTypography>
              {(claimable > 0) && (
                <MKButton variant="contained" color="primary" onClick={(ev) => { handleClaim(ev);}}>
                  Claim
                </MKButton>
              )}
            </MKBox>
          </Grid>
        )}
      </Grid>
        
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Vesting;
