import React, { useState } from "react";
import { Formik, useField, Form, } from "formik";
import * as Yup from "yup";
import dayjs, { Dayjs } from 'dayjs';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Importing your custom components
import WalletsList from "./WalletsList";
import RecipientsList from "./RecipientsList";
import PresaleStagesList from "./PresaleStagesList";
import StakingSlotsList from "./StakingSlotsList";
import VestingSlotsList from "./VestingSlotsList";
import TrustDepositFields from "./TrustDepositFields";
import FormikTextField from "./FormikTextField";
import CPFeesFields from "./CPFeesFields";
import { useChainContext } from "contexts/ChainProvider";
import { FACTORY_CONTRACT } from "config/constant";
import DenomAmount from "components/Denom/DenomAmount";

function CreateTokenForm() {
  // You'd need to add the necessary state hooks for each field of your form,
  // and input validation depending on your business requirements.
  
  const validationSchema = Yup.object().shape({
    tokenName: Yup.string()
      .min(2, 'At least 2 characters required!')
      .max(30, '30 characters at maximum!')
      .required('Required'),
    symbol: Yup.string()
      .matches(/^[A-Z]{3,10}$/, 'Invalid symbol, minimum 3 characters, maximum 10 characters, only uppercase letters allowed')
      .required('Required'),
    decimals: Yup.number()
      .transform((value, originalValue) => {
        const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
        return cleaned ? +cleaned : cleaned;
      })
      .min(0, 'Must be 0 or higher')
      .max(12, 'Must be 12 or lower')
      .required('Required'),
    mintable: Yup.boolean(),
    initialWallets: Yup.array()
      .of(
        Yup.object().shape({
          address: Yup.string()
            .required('Required'),
          amount: Yup.number()
            .transform((value, originalValue) => {
              const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
              return cleaned ? +cleaned : cleaned;
            })    
            .required('Required'),
        })
      )
      .min(1, 'At least one wallet required')
      .required('Required'),
    staking: Yup.boolean(),
    stakingSettings: Yup.object().when('staking', {
      is: true,
      then: () => Yup.object().shape({
        initialDeposit: Yup.number()
          .transform((value, originalValue) => {
            const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
            return cleaned ? +cleaned : cleaned;
          })  
          .required('Required'),
        slots: Yup.array()
          .of(
            Yup.object().shape({
              identifier: Yup.string().required('Required'),
              minStake: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                }),
              maxStake: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                }),
              apr: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                })
                .required('Required'),
              lockPeriod: Yup.number().default(14)
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                })
                .required('Required'),
            })
          )
          .min(1, 'At least one slot required')
          .required('Required'),
      }),
      otherwise: () => Yup.object().notRequired()
    }),
    vesting: Yup.boolean(),
    vestingSettings: Yup.object().when('vesting', {
      is: true,
      then: () => Yup.object().shape({
        initialDeposit: Yup.number()
          .transform((value, originalValue) => {
            const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
            return cleaned ? +cleaned : cleaned;
          })  
          .required('Required'),
        slots: Yup.array()
          .of(
            Yup.object().shape({
              identifier: Yup.string().required('Required'),
              apr: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                })
                .required('Required'),
              aprAfterRelease: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                })        
                .required('Required'),
              releaseStartTime: Yup.date(),
              releasePeriod: Yup.number().default(180)
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                  return cleaned ? +cleaned : cleaned;
                })
                .required('Required'),
              releaseType: Yup.string()
                .oneOf(['linear', 'fixed'], 'Invalid release type')
                .required('Required'),
            })
          )
          .min(1, 'At least one slot required')
          .required('Required'),
      }),
      otherwise: () => Yup.object().notRequired()
    }),
    presale: Yup.boolean(),
    presaleSettings: Yup.object().when('presale', {
      is: true,
      then: () => Yup.object().shape({
        fundRecipients: Yup.array()
          .of(
            Yup.object().shape({
              address: Yup.string().required('Required'),
              share: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,]/g, '');
                  return cleaned ? +cleaned : cleaned;
                })
                .max(100, 'Share cannot exceed 100%')
                .required('Required'),
            })
          )
          .min(1, 'At least one fund recipient required')
          .required('Required'),
        cw20Recipients: Yup.array()
          .of(
            Yup.object().shape({
              address: Yup.string().required('Required'),
              share: Yup.number()
                .transform((value, originalValue) => {
                  const cleaned = ('' + originalValue).replace(/[,]/g, '');
                  //console.log(cleaned);
                  return cleaned ? +cleaned : cleaned;
                })        
                .max(100, 'Share cannot exceed 100%')
                .required('Required'),
            })
          ),
        whitelisted: Yup.array()
          .of(
            Yup.string().required('Required')
          ),
        carryOver: Yup.boolean(),
        unsoldTokensRecipient: Yup.string(),
        walletLimit: Yup.number()
          .transform((value, originalValue) => {
            const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
            return cleaned ? +cleaned : cleaned;
          }),
          stages: Yup.array()
          .of(
              Yup.object().shape({
                  name: Yup.string().required('Required'),
                  supply: Yup.number()
                      .transform((value, originalValue) => {
                          const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
                          return cleaned ? +cleaned : cleaned;
                      })
                      .required('Required'),
                  prices: Yup.array()
                      .of(
                          Yup.object().shape({
                              tokenType: Yup.string().oneOf(['native', 'dollar']).required('Required'),
                              denom: Yup.string().when('tokenType', {
                                  is: 'native',
                                  then: () => Yup.string().oneOf(['uluna', 'uusd']).required('Required'),
                                  otherwise: () => Yup.string().nullable().notRequired()
                              }),
                              amount: Yup.number()
                                  .transform((value, originalValue) => {
                                      const cleaned = ('' + originalValue).replace(/[,]/g, '');
                                      return cleaned ? +cleaned : cleaned;
                                  })
                                  .required('Required'),
                              minPriceInUluna: Yup.number().when('tokenType', {
                                  is: 'dollar',
                                  then: () => Yup.number()
                                  .transform((value, originalValue) => {
                                    const cleaned = ('' + originalValue).replace(/[,]/g, '');
                                    return cleaned ? +cleaned : cleaned;
                                  }).required('Required')
                              }),
                              maxPriceInUluna: Yup.number().when('tokenType', {
                                  is: 'dollar',
                                  then: () => Yup.number()
                                  .transform((value, originalValue) => {
                                    const cleaned = ('' + originalValue).replace(/[,]/g, '');
                                    return cleaned ? +cleaned : cleaned;
                                  }).required('Required')
                              })
                          })
                      )
                      .min(1, 'At least one price required')
                      .required('Required'),
                  startTime: Yup.date().required('Required'),
                  endTime: Yup.date().required('Required'),
                  vesting: Yup.array()
                      .of(
                          Yup.object().shape({
                              slot: Yup.string()
                                  .required('Required'),
                              share: Yup.number()
                                  .min(1)
                                  .required('Required'),
                          })
                      )
              })
          )
          .min(1, 'At least one stage required')
          .required('Required'),
      }),
      otherwise: () => Yup.object().notRequired()
    }),
    trustDeposit: Yup.object().shape({
      denom: Yup.string()
        .oneOf(['uluna', 'uusd'], 'Invalid token')
        .required('Required'),
      amount: Yup.number()
        .transform((value, originalValue) => {
          const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
          return cleaned ? +cleaned : cleaned;
        })  
        .min(2, 'Minimum deposit is 2,000,000 LUNC')
        .required('Required'),
    }),
    launchFees: Yup.object().shape({
      denom: Yup.string()
        .oneOf(['uluna', 'uusd'], 'Invalid token')
        .required('Required'),
      amount: Yup.number()
        .transform((value, originalValue) => {
          const cleaned = ('' + originalValue).replace(/[,\.]/g, '');
          return cleaned ? +cleaned : cleaned;
        })  
        .min(10, 'Fees to launch is 1,000,000 LUNC')
        .required('Required'),
    }),
  });

  
    // Initialize formik
    let initialValues = {
        tokenName: '',
        symbol: '',
        decimals: 6,
        mintable: false,
        initialWallets: [{ address: '', amount: '' }],
        staking: false,
        stakingSettings: {
            initialDeposit: '',
            slots: [{ identifier: '', minStake: '', maxStake: '', apr: '', lockPeriod: 14 }],
        },
        vesting: false,
        vestingSettings: {
            initialDeposit: '',
            slots: [{ identifier: '', apr: '', aprAfterRelease: '', releaseStartTime: '', releasePeriod: 180, releaseType: 'linear' }],
        },
        presale: false,
        presaleSettings: {
            fundRecipients: [{ address: '', share: '' }],
            cw20Recipients: [],
            carryOver: false,
            unsoldTokensRecipient: '',
            walletLimit: '',
            stages: [{
              name: '',
              whitelisted: [],
              supply: '',
              prices: [{ tokenType: 'native', denom: 'uluna', amount: '' }],
              startTime: null,
              endTime: null,
              vesting: [],
            }]
        },
        trustDeposit: {
            denom: 'uluna',
            amount: 2000000
        },
        launchFees: {
            denom: 'uluna',
            amount: 1000000
        },
        cw20RecipientsWarningAccepted: false,
    };

    const { chainClients, chainId, isBroadcasting, setBroadcasting, handleError } = useChainContext();

    // if localstorage contains saved values, use them
    const savedForm = localStorage.getItem('createTokenForm');
    if(savedForm) {
      let parsedForm = JSON.parse(savedForm);
      if(parsedForm) {
        // merge values
        if(parsedForm.presaleSettings?.stages && parsedForm.presaleSettings?.stages.length > 0) {
          parsedForm.presaleSettings.stages = parsedForm.presaleSettings.stages.map((stage) => {
            stage.startTime = dayjs(stage.startTime) || null;
            stage.endTime = dayjs(stage.endTime) || null;
            return stage;
          });
        }

        if(parsedForm.vestingSettings?.slots && parsedForm.vestingSettings?.slots.length > 0) {
          parsedForm.vestingSettings.slots = parsedForm.vestingSettings.slots.map((slot) => {
            slot.releaseStartTime = dayjs(slot.releaseStartTime) || null;
            return slot;
          });
        }

        if(parsedForm.initialWallets && parsedForm.initialWallets.length > 0) {
          parsedForm.initialWallets = parsedForm.initialWallets.map((wallet) => {
            wallet.amount = wallet.amount.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
            wallet.address = wallet.address.trim();
            return wallet;
          });
        }

        initialValues = {
          ...initialValues,
          ...parsedForm,
        };

        console.log('initialValues', initialValues)
      }
    }

    function FormikSwitch(props) {
        const [field, meta] = useField(props);
    
        return (
            <>
                <Switch {...field} {...props} checked={field.value} />
                {meta.touched && meta.error ? (
                    <div>{meta.error}</div>
                ) : null}
            </>
        );
    }

    const showError = (error) => {
        if(!error) {
          return null;
        }
        
        if(typeof error === 'string') {
          return (
              <div className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium">
                {error}
              </div>
            );
        }

        return null;
    }

    const onFormikSubmit = async (values, { setSubmitting, errors }) => {
        if(!chainId || !chainClients[chainId]) {
          handleError('Please connect to a chain');
          return;
        }

        console.log('Formik errors', errors);

        // Validate Recipient shares
        let totalFundShare = 0;
        let totalCw20Share = 0;

        values.presaleSettings.fundRecipients.forEach((recipient) => {
          totalFundShare += recipient.share;
        });

        values.presaleSettings.cw20Recipients.forEach((recipient) => {
          totalCw20Share += recipient.share;
        });

        if(values.presaleSettings.stages.filter((stage) => stage.vesting.length > 0).length > 0 && !values.vesting) {
          handleError('Please enable vesting if you want to use this feature in presale.');
          return;
        }

        for(const vesting of values.presaleSettings.stages.filter((stage) => stage.vesting.length > 0)) {
          let totalVestingShare = 0;
          let availableSlots = values.vestingSettings.slots.map((slot) => slot.identifier);
          for(const vestingSlot of vesting.vesting) {
            totalVestingShare += vestingSlot.share;

            if(availableSlots.indexOf(vestingSlot.slot) === -1) {
              handleError(`Vesting slot ${vestingSlot.slot} not found in vesting settings`);
              return;
            }
          }

          if(totalVestingShare > 100) {
            handleError(`Vesting shares for presale slot ${vesting.identifier} exceed 100%`);
            return;
          }
          
        }

        if (totalFundShare > 100) {
          handleError('Fund Recipients shares exceed 100%');
          return;
        }

        if (totalCw20Share > 100) {
          handleError('CW20 Recipients shares exceed 100%');
          return;
        }

        if (totalCw20Share > 10) {
          const confirm = window.confirm('CW20 Recipients shares exceed 10%, are you sure you want to continue?');
          if (!confirm) return;
        }

        // Process token creation with valid data
        console.log(values);

        setBroadcasting(true);
        chainClients[chainId].createToken(FACTORY_CONTRACT, values).then((data) => {
            console.log('createToken', data);
            // remove data from local storage
            localStorage.removeItem('createTokenForm');

            handleError('Token created successfully', 'Success');
        }).catch((err) => {
            // handle error or ignore
            console.log('createToken err', err);
            handleError(err);
        }).finally(() => {
            setBroadcasting(false);
        });
    };

  

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Create Token
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  onFormikSubmit(values, actions);
                }}
                >
          {props => {
            const handleManualSubmit = async (e) => {
              e.preventDefault();
              e.stopPropagation();

              // save form contents into localstorage
              localStorage.setItem('createTokenForm', JSON.stringify(props.values));


              // Manually trigger validation
              await props.validateForm();
  
              // Check for validation errors
              if (Object.keys(props.errors).length > 0) {
                  console.log("Validation errors:", props.errors);
                  // Optionally display errors in an alert or other UI elements
                  alert("Validation errors: " + JSON.stringify(props.errors));
              } else {
                  props.handleSubmit();
              }
            };

            const totalSupply = props.values.initialWallets.reduce((total, wallet) => {
              return total + parseInt(wallet.amount.replace(/[,\.]/g, ''));
            }, 0) + parseInt(props.values.stakingSettings.initialDeposit.replace(/[,\.]/g, '') || "0") + parseInt(props.values.vestingSettings.initialDeposit.replace(/[,\.]/g, '') || "0") + props.values.presaleSettings.stages.reduce((total, stage) => {
              return total + parseInt(stage.supply.replace(/[,\.]/g, ''));
            }, 0);

            return (
          <Form onSubmit={handleManualSubmit}>
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Token Details
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    variant="standard"
                    label="Token Name"
                    fullWidth
                    name="tokenName"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikTextField
                    variant="standard"
                    label="Symbol"
                    fullWidth
                    name="symbol"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    variant="standard"
                    type="number"
                    label="Decimals"
                    fullWidth
                    name="decimals"
                    inputProps={{ min: 0, max: 12, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<FormikSwitch name="mintable" />}
                    label="Mintable"
                  />
                  <MKTypography variant="body2" color="secondary">
                    If mintable is enabled, the token will be created with a minter role. This means the owner of the token can mint new tokens at any time.
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Initial Wallet Balance
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                    <MKTypography variant="body2" color="secondary">
                      The initial wallet balance is the amount of tokens that will be sent to the initial wallets when the token is created. You can add as many wallets as you want. At least one wallet is necessary
                    </MKTypography>
                    <MKTypography variant="body2" color="secondary">
                      <strong>Attention!</strong> The initial total supply of the token is calculated by the sum of all wallet balances and the balance of staking, vesting and presale contracts.
                    </MKTypography>
                    <WalletsList fieldName="initialWallets" />
                    {showError(props.errors.initialWallets)}
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Staking
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    Staking allows users to lock their tokens for a period of time and earn rewards. You can add as many slots as you want. At least one slot is necessary.
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    <strong>Attention!</strong> Don&apos;t forget to set enough tokens for the staking contract balance to cover the rewards.
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <FormikSwitch
                            color="primary"
                            name="staking"
                            />
                        }
                        label="Enable Staking"
                        />
                </Grid>
                  {(props.values.staking && (<Grid item xs={12}>
                    <FormikTextField
                      variant="standard"
                      type="number"
                      label={`Initial Deposit (${props.values.symbol || 'TOKEN'})`}
                      fullWidth
                      name="stakingSettings.initialDeposit"
                      inputProps={{ min: 0, max: 12, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>))}
                <Grid item xs={12}>
                  <StakingSlotsList />
                  
                  {showError(props.errors.stakingSettings?.slots)}
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Vesting
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    Vesting gives the token creator the opportunity to provide users with airdropped tokens that are locked and slowly released over a predefined period of time. You can add as many slots as you want. At least one slot is necessary.
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    <strong>Attention!</strong> Don&apos;t forget to set enough tokens for the vesting contract balance to cover the rewards if you want to provide rewards for locked tokens.
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <FormikSwitch
                            color="primary"
                            name="vesting"
                        />
                    }
                    label="Enable Vesting"
                    />
                </Grid>
                {(props.values.vesting && (
                  <Grid item xs={12}>
                    <FormikTextField
                      variant="standard"
                      type="number"
                      label={`Initial Deposit (${props.values.symbol || 'TOKEN'})`}
                      fullWidth
                      name="vestingSettings.initialDeposit"
                      inputProps={{ min: 0, max: 12, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </Grid>))}
                <Grid item xs={12}>
                  <VestingSlotsList />
                  {showError(props.errors.vestingSettings?.slots)}

                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Presale
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    Presale allows users to buy tokens at a (mostly discounted) price before the token is listed on the market. You can add as many stages as you want. At least one stage is necessary.
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <FormikSwitch
                            color="primary"
                            name="presale"
                        />
                    }
                    label="Enable Presale"
                    />
                  
                    <>
                      
                      <RecipientsList fieldName="fundRecipients" />
                      {showError(props.errors.presaleSettings?.fundRecipients)}

                      <RecipientsList fieldName="cw20Recipients" />
                      {showError(props.errors.presaleSettings?.cw20Recipients)}
                      {/*<FormControlLabel
                        control={
                            <FormikSwitch
                            color="primary"
                            {...cw}
                            checked={}
                            />
                        }
                        label="Accept CW20 Recipients Warning"
                    />*/}
                      <PresaleStagesList />
                      {showError(props.errors.presaleSettings?.stages)}

                      {(props.values.presale && (
                        <FormControlLabel
                          control={
                              <FormikSwitch
                                  color="primary"
                                  name="presaleSettings.carryOver"
                              />
                          }
                          label="Carry over unsold funds from one stage to the next"
                          />
                      ))}
                    
                    </>
                  
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Trust Deposit
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    Trust Deposit allows users to deposit a certain amount of LUNC or USTC to the token factory contract. These tokens are locked for 12 months. In the case of malicious behavior, the tokens can be used to compensate the holders by a governance proposal. The tokens can be claimed back by the token owner after 12 months.
                  </MKTypography>
                  <TrustDepositFields />
                </Grid>
                <Grid item xs={12}>
                  <MKTypography variant="h6">
                    Launch Fees
                  </MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    Launch fees are paid to the Terra Classic Community Pool.
                  </MKTypography>
                  <CPFeesFields />
                </Grid>
              </Grid>
              <Grid item xs={12} mt={4}>
                {(totalSupply > 0) && (
                  <MKTypography variant="h6">
                    Total Supply: <strong>{totalSupply.toLocaleString(undefined, props.values?.decimals || 0)} {props.values?.symbol}</strong>
                  </MKTypography>
                  )}
              </Grid>
              {showError(props.errors.trustDeposit)}
              {showError(props.errors.launchFees)}
              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton type="submit" variant="gradient" color="dark" fullWidth disabled={isBroadcasting} onClick={handleManualSubmit}>
                  {isBroadcasting ? 'Creating Token...' : 'Create Token'}
                </MKButton>
              </Grid>
            </MKBox>
          </Form>
          );
          }}
          </Formik>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default CreateTokenForm;
