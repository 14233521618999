import { IconButton, Grid, TextField } from "@mui/material";
import { useFormikContext } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormikTextField from "./FormikTextField";

const StakingSlotsList = () => {
  const { values, setFieldValue } = useFormikContext();

  if (!values.staking) {
    return null;
  }

  return (
    <>
      {values.stakingSettings.slots.map((item, index) => (
        <Grid container spacing={3} key={index}>
          <Grid item xs={3} md={3}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.stakingSettings.slots[index].identifier}
              name={`stakingSettings.slots[${index}].identifier`}
              label="Identifier"
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.stakingSettings.slots[index].minStake}
              name={`stakingSettings.slots[${index}].minStake`}
              label="Min Stake"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.stakingSettings.slots[index].maxStake}
              name={`stakingSettings.slots[${index}].maxStake`}
              label="Max Stake"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.stakingSettings.slots[index].apr}
              name={`stakingSettings.slots[${index}].apr`}
              label="APR"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.stakingSettings.slots[index].lockPeriod}
              name={`stakingSettings.slots[${index}].lockPeriod`}
              label="Lock Period"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => {
              const list = [...values.stakingSettings.slots];
              list.splice(index, 1);
              setFieldValue('stakingSettings.slots', list);
            }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => {
          setFieldValue('stakingSettings.slots', [...values.stakingSettings.slots, { identifier: '', minStake: '', maxStake: '', apr: '', lockPeriod: '' }]);
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </>
  );
}

export default StakingSlotsList;
