import { IconButton, Grid, TextField, Select, MenuItem } from "@mui/material";
import { Field, useFormikContext } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormikTextField from "./FormikTextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

const PresaleStagesList = ({ fieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  if (!values.presale) {
    return null;
  }

  return (
    <>
      <MKTypography variant="body2" color="secondary">
        Here you can set the stages of the presale. You can add as many stages as you want. The sum of all supplies for the stages is added to the initial balance of the contract and thus the total supply.
      </MKTypography>
      {values.presaleSettings.stages.map((stage, index) => (
        <Grid container spacing={3} key={index} sx={{mb: 5}}>
          <Grid item xs={12}>
            <MKTypography variant="h6" color="secondary">Name of this Stage</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <FormikTextField 
                variant="standard"
                fullWidth
                value={values.presaleSettings.stages[index].name}
                name={`presaleSettings.stages[${index}].name`}
                label="Stage name"
                type="text"
              />
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h6" color="secondary">Amount of tokens to buy</MKTypography>
          </Grid>
          <Grid item xs={4} md={4}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.presaleSettings.stages[index].supply}
              name={`presaleSettings.stages[${index}].supply`}
              label="Supply"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="h6" color="secondary">Prices</MKTypography>
          </Grid>

          <Grid item xs={12}>
          {stage.prices.map((price, priceIndex) => (
            <Grid container spacing={3} key={priceIndex}>
              <Grid item xs={12} md={3}>
                <Field
                  variant="standard"
                  name={`presaleSettings.stages[${index}].prices[${priceIndex}].tokenType`}
                  as={Select}
                  label="Token Type"
                  fullWidth
                  value={price.tokenType}
                  displayEmpty={true}
                >
                  <MenuItem value="native">Native Token</MenuItem>
                  <MenuItem value="dollar">Dollar Value</MenuItem>
                </Field>
              </Grid>

              {price.tokenType === 'native' && (
                <>
                  <Grid item xs={12} md={4}>
                    <Field
                      variant="standard"
                      name={`presaleSettings.stages[${index}].prices[${priceIndex}].denom`}
                      as={Select}
                      label="Denomination"
                      fullWidth
                      value={price.denom}
                      displayEmpty={true}
                    >
                      <MenuItem value="uluna">LUNC</MenuItem>
                      <MenuItem value="uusd">USTC</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={11} md={4}>
                    <FormikTextField 
                      variant="standard"
                      fullWidth
                      value={price.amount}
                      name={`presaleSettings.stages[${index}].prices[${priceIndex}].amount`}
                      label="Price"
                      type="number"
                      decimalScale={6}
                      inputProps={{ min: 0, step: 0.000001, inputMode: 'decimal' }}
                    />
                  </Grid>
                </>
              )}

              {price.tokenType === 'dollar' && (
                <>
                  <Grid item xs={12} md={2}>
                    <FormikTextField 
                      variant="standard"
                      fullWidth
                      value={price.amount}
                      name={`presaleSettings.stages[${index}].prices[${priceIndex}].amount`}
                      label="Dollar Price"
                      type="number"
                      decimalScale={2}
                      inputProps={{ min: 0, step: 0.01, inputMode: 'decimal' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormikTextField 
                      variant="standard"
                      fullWidth
                      value={price.minPriceInUluna}
                      name={`presaleSettings.stages[${index}].prices[${priceIndex}].minPriceInUluna`}
                      label="Min Price (LUNC)"
                      type="number"
                      decimalScale={6}
                      inputProps={{ min: 0, step: 0.000001, inputMode: 'decimal' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormikTextField 
                      variant="standard"
                      fullWidth
                      value={price.maxPriceInUluna}
                      name={`presaleSettings.stages[${index}].prices[${priceIndex}].maxPriceInUluna`}
                      label="Max Price (LUNC)"
                      type="number"
                      decimalScale={6}
                      inputProps={{ min: 0, step: 0.000001, inputMode: 'decimal' }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={1}>
                <IconButton onClick={() => {
                  const stages = [...values.presaleSettings.stages];
                  stages[index].prices.splice(priceIndex, 1);
                  setFieldValue('presaleSettings.stages', stages);
                }}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          </Grid>

          <Grid item xs={12}>
              <IconButton onClick={() => {
                  const stages = [...values.presaleSettings.stages];
                  stages[index].prices.push({ tokenType: 'native', denom: 'uluna', amount: '' });
                  setFieldValue('presaleSettings.stages', stages);
              }}>
                  <AddCircleOutlineIcon sx={{marginRight: 1}}/> <MKTypography variant="body2" color="secondary">Add new price</MKTypography>
              </IconButton>
          </Grid>

          <Grid item xs={6} md={6}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start Time"
              value={values.presaleSettings.stages[index].startTime}
              onChange={(value) => {
                const stages = [...values.presaleSettings.stages];
                stages[index].startTime = value;
                setFieldValue('presaleSettings.stages', stages);
              }}
            />
          </Grid>

          <Grid item xs={5} md={5}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="End Time"
              value={values.presaleSettings.stages[index].endTime}
              onChange={(value) => {
                const stages = [...values.presaleSettings.stages];
                stages[index].endTime = value;
                setFieldValue('presaleSettings.stages', stages);
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <IconButton onClick={() => {
              const list = [...values.presaleSettings.stages];
              list.splice(index, 1);
              setFieldValue('presaleSettings.stages', list);
            }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="body2" color="secondary">
              You can send shares of the bought cw20 token to the vesting contract. This is useful if you want to lock the tokens for a certain period of time. You can add as many vesting slots as you want. Keep in mind that you need to have this vesting slots defined for your vesting settings.
            </MKTypography>
            {stage.vesting?.map((vesting, vestingIndex) => (
              <Grid item xs={12} key={vestingIndex} container gap={2}>
                <Grid item xs={5} md={5}>
                  <Field
                    variant="standard"
                    name={`presaleSettings.stages[${index}].vesting[${vestingIndex}].slot`}
                    as={Select}
                    label={"Vesting Slot"}
                    fullWidth
                    value={vesting.slot}
                    displayEmpty={true}
                  >
                    <MenuItem value=""></MenuItem>
                    {(values.vestingSettings?.slots || []).map((slot, slotIndex) => (
                      <MenuItem key={slotIndex} value={slot.identifier}>{slot.identifier}</MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={5} md={5}>
                  <FormikTextField 
                    variant="standard"
                    fullWidth
                    value={vesting.share}
                    name={`presaleSettings.stages[${index}].vesting[${vestingIndex}].share`}
                    label="Percentage"
                    type="number"
                    decimalScale={2}
                    min={0}
                    max={100}
                    inputProps={{ min: 0, max: 100, step: 0.01, inputMode: 'decimal' }}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <IconButton onClick={() => {
                    const stages = [...values.presaleSettings.stages];
                    stages[index].vesting.splice(vestingIndex, 1);
                    setFieldValue('presaleSettings.stages', stages);
                  }}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={6} md={6}>
              <IconButton onClick={() => {
                const stages = [...values.presaleSettings.stages];
                stages[index].vesting.push({ slot: '', share: '' });
                  setFieldValue('presaleSettings.stages', stages);
                }}>
                <AddCircleOutlineIcon sx={{marginRight: 1}}/> <MKTypography variant="body2" color="secondary">Add new vesting share</MKTypography>
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MKTypography variant="body2" color="secondary">
              You can add wallet addresses that should be whitelisted for this presale stage. If this list is empty, every wallet can buy tokens during this stage. As soon as at least one address is given, only these addresses can buy tokens.
            </MKTypography>
            {stage.whitelisted?.map((wallet, walletIndex) => (
              <Grid item xs={12} key={walletIndex} container>
                <Grid item xs={11} md={11}>
                  <FormikTextField 
                    variant="standard"
                    fullWidth
                    value={wallet}
                    name={`presaleSettings.stages[${index}].whitelisted[${walletIndex}]`}
                    label="Whitelisted Wallet Address"
                    type="text"
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <IconButton onClick={() => {
                    const stages = [...values.presaleSettings.stages];
                    stages[index].whitelisted.splice(walletIndex, 1);
                    setFieldValue('presaleSettings.stages', stages);
                  }}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Grid item xs={6} md={6}>
              <IconButton onClick={() => {
                const stages = [...values.presaleSettings.stages];
                stages[index].whitelisted.push('');
                setFieldValue('presaleSettings.stages', stages);
              }}>
                <AddCircleOutlineIcon sx={{marginRight: 1}}/> <MKTypography variant="body2" color="secondary">Add whitelisted wallet</MKTypography>
              </IconButton>
            </Grid>
          </Grid>

        </Grid>
      ))}
      <Grid item xs={12}>
        <hr />
        <IconButton onClick={() => {
          setFieldValue('presaleSettings.stages', [...values.presaleSettings.stages, { name: '', supply: '', prices: [{ tokenType: 'native', denom: 'uluna', amount: '' }], whitelisted: [], vesting: [], startTime: null, endTime: null }]);
        }}>
         <AddCircleOutlineIcon sx={{marginRight: 1}} /> <MKTypography variant="body2" color="secondary">Add new presale stage</MKTypography>
        </IconButton>
      </Grid>
    </>
  );
}

export default PresaleStagesList;
