import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';

const PresaleForm = ({ conversionRate, handleBuy, reloadData, denomData }) => {
  const [tokenAmount, setTokenAmount] = useState('');

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (value >= 0) {
      setTokenAmount(value);
    }
  };

  useEffect(() => {
    setTokenAmount(0);
  }, [conversionRate, reloadData]);

  const formattedTokens = new Intl.NumberFormat('en-US').format(tokenAmount / conversionRate);

  if(!denomData) return null;

  return (
    <MKBox component="form" display="flex" alignItems="center" justifyContent="center" gap={2} sx={{p: 4}}>
      {(conversionRate === 0) ? (
        <MKTypography variant="body2" color="secondary">
          Loading data …
        </MKTypography>
      ) : (
        <>
        <MKInput
          label="Amount of Tokens"
          variant="outlined"
          type="number"
          value={tokenAmount}
          onChange={handleAmountChange}
          InputProps={{
            endAdornment: (
              <Typography variant="body2" color="secondary" style={{whiteSpace: "nowrap"}}>
                = {formattedTokens} LUNC
              </Typography>
            )
          }}
        />
        <MKButton variant="contained" color="primary" onClick={(ev) => { handleBuy(ev, tokenAmount, (tokenAmount / conversionRate));}}>
          Buy
        </MKButton>
        <MKTypography variant="body2" color="secondary">
          1 LUNC = {conversionRate.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {denomData.symbol}, 1 {denomData.symbol} = {(1/conversionRate).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} LUNC
        </MKTypography>
        </>
      )}
    </MKBox>
  );
};

export default PresaleForm;
