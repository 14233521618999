import MKTypography from 'components/MKTypography';

const VestingSlotBox = ({ slot }) => {
  
  if(!slot) return null;

  return (
    <>
      <MKTypography variant="h6" color="secondary">
        {slot.identifier}
      </MKTypography>
      <MKTypography variant="body2" color="secondary">
        Vesting period: {slot.release_period === 0 ? 'immediate' : `${slot.release_period} day${(slot.release_period !== 1 ? 's' : '')} (${slot.release_type === 'fixed' ? 'Fixed' : 'Linear'})`}<br />
        {(slot.release_starts_after > 0) && (
          <>
          Earliest release date: {(new Date(slot.release_starts_after/1000000)).toLocaleDateString()}
          </>
        )}

      </MKTypography>
      
      <MKTypography variant="body2" color="secondary">
      APR: {slot.apr > 0 ? `${slot.apr}%` : "n/a"}<br />
      APR after release: {slot.apr_after_release > 0 ? `${slot.apr_after_release}%` : "n/a"}
      </MKTypography>
      
    </>
  );
};

export default VestingSlotBox;
