/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import PresaleForm from "components/TokenFactory/PresaleForm";
import { useEffect, useState } from "react";
import { useChainContext } from "contexts/ChainProvider";
import MKTypography from "components/MKTypography";
import ProgressBar from "components/Progressbar/Progressbar";
import { useParams } from "react-router-dom";
import { useDenomInfo } from "hooks/useDenomInfo";
import { Box } from "@mui/material";
import { FACTORY_CONTRACT } from "config/constant";

function Presale() {

  const { token: tokenContract } = useParams();

  const [conversionRate, setConversionRate] = useState(0);
  //const [progress, setProgress] = useState({ phase1: 0, phase2: 0, phase3: 0, tokens_total_1: 0, tokens_sold_1: 0, tokens_total_2: 0, tokens_sold_2: 0, tokens_total_3: 0, tokens_sold_3: 0 });
  const [phase, setPhase] = useState(1);
  const [price, setPrice] = useState(0);
  const [holdings, setHoldings] = useState(0);
  const [reloadData, setReloadData] = useState(false);
  const [presaleStages, setPresaleStages] = useState([]);
  const [presaleContract, setPresaleContract] = useState(null);
  const [canFinish, setCanFinish] = useState(false);

  const { chainClients, chainId, setBroadcasting, handleError } = useChainContext();

  const denomData = useDenomInfo(tokenContract, chainId);
  
  const handleBuy = async (ev, amount, luncAmount) => {
    ev.preventDefault();
    
    console.log('buy');

    setBroadcasting(true);
    chainClients[chainId].presaleBuy(presaleContract, amount, luncAmount).then((data) => {
        alert('Bought.');
    }).catch((err) => {
        // handle error or ignore
        handleError(err);
    }).finally(() => {
        setBroadcasting(false);
        setReloadData(!reloadData);
    });
        
    console.log('Buying Token with ' + amount + ' LUNC');
  };

  const handleFinish = async (ev) => {
    ev.preventDefault();

    console.log('finish');

    setBroadcasting(true);
    chainClients[chainId].presaleFinish(presaleContract).then((data) => {
        alert('Finished.');
    }).catch((err) => {
        // handle error or ignore
        handleError(err);
    }).finally(() => {
        setBroadcasting(false);
        setReloadData(!reloadData);
    });
  };

  const formatDenom = (amount) => {
    return (amount / Math.pow(10, denomData.decimals)).toLocaleString(undefined, {maximumFractionDigits: denomData.decimals});
  }

  useEffect(() => {
    if(!tokenContract || !chainClients || !chainClients[chainId]) {
      return;
    }

    chainClients[chainId].getFactoryTokenInfo(FACTORY_CONTRACT, tokenContract).then((res) => {
      console.log(res);
      if(!res?.settings?.presale?.contract) {
        return;
      }

      setPresaleContract(res.settings.presale.contract);
    });
  }, [chainClients, chainId, tokenContract]);

  useEffect(() => {
    console.log('useEffect', chainId, presaleContract, chainClients)
    if(!presaleContract || !chainClients || !chainClients[chainId]) {
      return;
    }

    chainClients[chainId].getPresaleInfo(presaleContract).then((res) => {
      console.log(res);
      if(!res || !res.stages || res.stages.length < 1) {
        return;
      }
      
      setPhase(res.stage);
      setPresaleStages(res.stages.sort((a, b) => a.start_time - b.stage_start_time));
      
      if(res.stage) {
        console.log('Conversion rate', res.stage.prices.find((price) => price.native_price?.denom === 'uluna').native_price?.amount);
        setPrice(res.stage.prices.find((price) => price.native_price?.denom === 'uluna').native_price?.amount / 1000000);
        setConversionRate(1 / res.stage.prices.find((price) => price.native_price?.denom === 'uluna').native_price?.amount * 1000000);
      } else {
        setPrice(0);
        setConversionRate(0);
      }
    });

    if(chainClients[chainId].wallet) {
      console.log('getPresaleHolderInfo', chainClients[chainId].wallet);
      chainClients[chainId].getPresaleHolderInfo(presaleContract, chainClients[chainId].wallet.walletAddress).then((res) => {
        console.log(res);
        if(!res || !res.amount) {
          return;
        }
        
        setHoldings((res.amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}));
      });

      chainClients[chainId].canFinishPresale(presaleContract, chainClients[chainId].wallet.walletAddress).then((res) => {
        console.log(res);
        if(!res) {
          return;
        }
        
        setCanFinish(res ? true : false);
      });
    }
  }, [chainClients, chainId, reloadData, presaleContract]);

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://appseed.us/product/material-kit/api-server-nodejs/react/",
            label: "download",
            color: "info",
          }}
        />
      </MKBox>
      {(conversionRate > 0) && (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            sm={10}
            md={9}
            lg={8}
            xl={8}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 2, sm: 2, md: 2 }}
              mx={3}
            >
              <PresaleForm handleBuy={handleBuy} conversionRate={conversionRate} denomData={denomData}/>
            </MKBox>
          </Grid>
        </Grid>
      )}
      {(canFinish) && (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={9} lg={8} xl={8} ml={{ xs: "auto", lg: 6 }} mr={{ xs: "auto", lg: 6 }}>
            <MKButton variant="contained" color="success" onClick={handleFinish}>
              Finish Presale
            </MKButton>
          </Grid>
        </Grid>
      )}
      {(presaleStages.length > 0 && denomData) && (
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={9} lg={8} xl={8} ml={{ xs: "auto", lg: 6 }} mr={{ xs: "auto", lg: 6 }}>          
            <MKBox mt={20}>
              <MKTypography variant="h4">Your bought tokens: <em>{holdings}</em> {denomData.symbol}</MKTypography>
              {(price > 0) && (<MKTypography variant="h5">Current price: <em>{price}</em> LUNC per {denomData.symbol}</MKTypography>)}

              {presaleStages.map((stage, index) => {
                const percentage = 100 * (1 - (stage.remaining_supply / stage.total_supply));
                const stage_percentage = (stage.end_time < phase?.start_time ? 100 : percentage);

                const tokens_sold = stage.total_supply - stage.remaining_supply;
                const tokens_total = stage.total_supply;

                console.log(stage, percentage, stage_percentage, tokens_sold, tokens_total);

                const progress_string = (Math.floor(100 * stage_percentage) / 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
                const label = `${progress_string}% (${formatDenom(tokens_sold)} / ${formatDenom(tokens_total)} ${denomData.symbol})`;

                const now = new Date().getTime();
                const start_time = stage.start_time / 1000000;
                const end_time = stage.end_time / 1000000;

                return (
                  <>
                  <Box display="flex" alignItems="center" flexDirection="column" p={1} m={1} bgcolor="background.paper" position="relative">
                    <MKBox bgColor={(now > end_time ? "error" : (now < start_time ? "info" : "success"))} color="white" style={{padding: "4px 12px", fontSize: "1rem"}} position="absolute" top="0" right="0" zIndex="0">{(now < start_time ? "Upcoming" : (now > end_time ? "Ended" : "Active"))}</MKBox>
                    <MKTypography variant="h6" component="div" color="secondary">
                      {stage.name} <small>({(new Date(stage.start_time / 1000000)).toLocaleString()} - {(new Date(stage.end_time / 1000000)).toLocaleString()})</small>
                    </MKTypography>

                    <ProgressBar key={stage.name} phase={stage.name} progress={stage_percentage} label={label} tokens_sold={tokens_sold} tokens_total={tokens_total} />
                  </Box>
                  </>
                );
              })}
            </MKBox>
          </Grid>
        </Grid>
      )}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presale;
