/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "./auth-context/auth.context";
import { WalletProvider, getChainOptions } from "@terra-money/wallet-provider";

let user = localStorage.getItem("user");
user = JSON.parse(user);

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <AuthProvider userData={user}>
      <WalletProvider {...chainOptions}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </WalletProvider>
    </AuthProvider>,
    document.getElementById("root")
  );
}).catch((err) => {
  console.log('load err', err);
  // eslint-disable-next-line no-alert
  window.alert('Error try again.');
});

