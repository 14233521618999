import { AuthorizationGrant, Coin, Coins, Delegation, ExtensionOptions, Fee, GenericAuthorization, LCDClient, MsgExecuteContract, MsgGrantAuthorization, MsgRevokeAuthorization, SendAuthorization, StakeAuthorization, StakeAuthorizationValidators, TxInfo, Wallet } from "@terra-money/terra.js";
import { ConnectedWallet } from "@terra-money/wallet-provider";
//import { ConnectedWallet } from "@terra-money/wallet-types";

/**
 * @param {Wallet | ConnectedWallet} x
 * @returns {x is ConnectedWallet}
 */
function isConnectedWallet(x: any) {
  if(!x) {
    return false;
  }
  return typeof x.key === "undefined";
};

async function getTaxAmount(amount?: Coins, lcd?: LCDClient) {
  if(!amount || !lcd) {
    return undefined;
  }

  const taxRate = await(
    await fetch(lcd.config.URL + '/terra/treasury/v1beta1/tax_rate', {
      redirect: 'follow'
    })
  ).json();

  let taxes = new Coins();
  for(let coin of amount.toArray()) {
    const tax = coin.amount.toNumber() * taxRate.tax_rate;
    taxes = taxes.add(new Coin(coin.denom, tax));
  }
  
  return taxes;
};

async function getTransactionData(messages: any, senderAddress: string, funds: Coins, client: LCDClient, fcdUrl: string, isClassic: boolean): Promise<ExtensionOptions> {
  let tax;
  if(isClassic) {
    tax = await getTaxAmount(funds, client);
  }

  const gasPrices = await(
    await fetch(fcdUrl + '/v1/txs/gas_prices', {})
  ).json();

  const use_fee_denoms = funds.denoms();
  if(use_fee_denoms.length === 0) {
    use_fee_denoms.push('uluna');
  }

  const gasAdjustment = 4;
  const gasPricesCoins = new Coins(gasPrices)
  console.log(funds.denoms(), gasPricesCoins);

  const account = await client.auth.accountInfo(senderAddress);
  const signerDataArray = [{
    address: senderAddress,
    publicKey: account.getPublicKey(),
    sequenceNumber: account.getSequenceNumber()
  }];

  var txFee = await client.tx.estimateFee(signerDataArray, { msgs: messages, gasPrices: gasPricesCoins, gasAdjustment: gasAdjustment, feeDenoms: use_fee_denoms });
  
  let txdata : ExtensionOptions = {
      msgs: messages,
      isClassic: isClassic,
      feeDenoms: use_fee_denoms,
  };

  console.log(gasPrices, use_fee_denoms, txdata, txFee);

  if(tax) {
    txdata.fee = new Fee(txFee.gas_limit, txFee.amount.add(tax));
  } else {
    txdata.fee = new Fee(txFee.gas_limit, txFee.amount);
  }
  
  return txdata;
}

async function waitForInclusionInBlock(lcd: LCDClient, txHash: string): Promise<TxInfo | undefined> {
  let res;
  for (let i = 0; i <= 50; i++) {
    try {
      res = await lcd.tx.txInfo(txHash);
    } catch (error) {
      // NOOP
    }
      
    if (res) {
      break;
    }
      
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
      
  return res;
};

export type Chain = {
  id: Number,
  name: string,
  chainId: string,
  lcdUrl: string,
  fcdUrl: string,
  isClassic: boolean
};
export type Addr = string;

export interface ChainReadOnlyInterface {
  chain: Chain,
  getFactoryTokenInfo: (contract: string, tokenContract: string) => Promise<any>;

  getPresaleInfo: (contract: string) => Promise<any>;
  getPresaleHolderInfo: (contract: string, address: string) => Promise<any>;
  canFinishPresale: (contract: string, address: string) => Promise<any>;
  
  getConfig: (contract: string) => Promise<any>;

  getTokenInfo: (contract: string) => Promise<any>;

  getVestingHolderInfo: (contract: string, address: string) => Promise<any>;
}
export class ChainQueryClient implements ChainReadOnlyInterface {
  chain: Chain;
  client: LCDClient;

  constructor(client: LCDClient, chain: Chain) {
    this.chain = chain;
    this.client = client;
    this.getFactoryTokenInfo = this.getFactoryTokenInfo.bind(this);
    this.getPresaleInfo = this.getPresaleInfo.bind(this);
    this.getPresaleHolderInfo = this.getPresaleHolderInfo.bind(this);
    this.canFinishPresale = this.canFinishPresale.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.getTokenInfo = this.getTokenInfo.bind(this);
    this.getVestingHolderInfo = this.getVestingHolderInfo.bind(this);
  }

  getFactoryTokenInfo = async (contract: string, tokenContract: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      token_info: {
        address: tokenContract
      }
    }).catch((error) => console.log('getFactoryTokenInfo  error ', error));
  };

  getPresaleInfo = async (contract: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      presale_info: {}
    }).catch((error) => console.log('getPresaleInfo  error ', error));
  };

  canFinishPresale = async (contract: string, address: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      can_finish: {
        address: address
      }
    }).catch((error) => console.log('canFinishPresale  error ', error));
  };

  getPresaleHolderInfo = async (contract: string, address: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      holder_info: {
        address: address
      }
    }).catch((error) => console.log('getPresaleHolderInfo  error ', error));
  };

  getConfig = async (contract: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      config: {}
    }).catch((error) => console.log('getConfig  error ', error));
  };

  getTokenInfo = async (contract: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      token_info: {}
    }).catch((error) => console.log('getTokenInfo  error ', error));
  }

  getVestingHolderInfo = async (contract: string, address: string): Promise<any> => {
    return this.client.wasm.contractQuery(contract, {
      vesting_info: {
        recipient: address
      }
    }).catch((error) => console.log('error ', error));
  }
}

export interface ChainInterface extends ChainReadOnlyInterface {
  executeTransaction: ( messages: any, funds: Coins, ) => Promise<any>;
  createToken: ( contract: string, values: any, ) => Promise<any>;
  presaleBuy: ( contract: string, amount: number, luncAmount: number, ) => Promise<any>;
  presaleFinish: ( contract: string, ) => Promise<any>;
  vestingClaim: ( contract: string, ) => Promise<any>;
}

export class ChainClient extends ChainQueryClient implements ChainInterface {
  client: LCDClient;
  wallet: any;

  constructor(client: LCDClient, wallet: Wallet | ConnectedWallet, chain: Chain) {
    super(client, chain);
    this.chain = chain;
    this.client = client;
    this.wallet = wallet;
    this.executeTransaction = this.executeTransaction.bind(this);
    this.createToken = this.createToken.bind(this);
    this.presaleBuy = this.presaleBuy.bind(this);
    this.presaleFinish = this.presaleFinish.bind(this);
    this.vestingClaim = this.vestingClaim.bind(this);
  }

 executeTransaction = async (messages: any, funds: Coins): Promise<any> => {
    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;

    const txdata = await getTransactionData(messages, senderAddress, new Coins(), this.client, this.chain.fcdUrl, this.chain.isClassic);

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    } else {
      const execTx = await this.wallet.createAndSignTx(txdata);
      return this.client.tx.broadcast(execTx);
    }

 }

 createToken = async (contract: string, values: any): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
    console.log('createToken ', contract, senderAddress);

    let funds = new Coins();

    if(values.trustDeposit) {
      funds = funds.add(new Coin(values.trustDeposit.token === 'USTC' ? 'uusd' : 'uluna', Math.floor(parseInt(values.trustDeposit.amount.replace(/,/g, '')) * Math.pow(10, 6)).toFixed(0)));
      console.log('trustDeposit ', values.trustDeposit.token, values.trustDeposit.amount, funds);
    }
    if(values.launchFees) {
      funds = funds.add(new Coin(values.launchFees.token === 'USTC' ? 'uusd' : 'uluna', Math.floor(parseInt(values.launchFees.amount.replace(/,/g, '')) * Math.pow(10, 6)).toFixed(0)));
      console.log('launchFees ', values.launchFees.token, values.launchFees.amount, funds);
    }


    let staking_slots: any = {};
    if(values.staking) {
      for(const slot of values.stakingSettings.slots) {
        staking_slots[slot.identifier] = {
          min_stake: slot.minStake ? Math.floor(parseInt(slot.minStake.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0) : null,
          max_stake: slot.maxStake ? Math.floor(parseInt(slot.maxStake.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0) : null,
          apr: slot.apr ? parseInt(slot.apr) : 0,
          lock_period: slot.lockPeriod ? parseInt(slot.lockPeriod) : 0,
        };
      }
    }

    let vesting_slots: any = {};
    if(values.vesting) {
      for(const slot of values.vestingSettings.slots) {
        vesting_slots[slot.identifier] = {
          apr: slot.apr ? parseInt(slot.apr) : 0,
          apr_after_release: slot.aprAfterRelease ? parseInt(slot.aprAfterRelease) : 0,
          release_starts_after: slot.releaseStartTime ? (new Date(slot.releaseStartTime).getTime() * 1000000).toFixed(0) : null,
          release_period: slot.releasePeriod ? parseInt(slot.releasePeriod) : 0,
          release_type: slot.releaseType ? slot.releaseType : 'linear'
        };
      }
    }

    let presale_stages: any = [];
    if(values.presale) {
      for(const stage of values.presaleSettings.stages) {
        let stage_prices = [];
        let has_dollar = false;
        let has_native = false;
        for(const price of stage.prices) {
          if(price.tokenType === 'dollar') {
            has_dollar = true;
            stage_prices.push({
              dollar_price: {
                price: Math.floor(parseFloat(price.amount.replace(/,/g, '')) * Math.pow(10, 6)).toFixed(0),
                min_price: Math.floor(parseFloat(price.minPriceInUluna.replace(/,/g, '')) * Math.pow(10, 6)).toFixed(0),
                max_price: Math.floor(parseFloat(price.maxPriceInUluna.replace(/,/g, '')) * Math.pow(10, 6)).toFixed(0),
              },
            });
          } else if(price.tokenType === 'native') {
            has_native = true;
            stage_prices.push({
              native_price: {
                denom: price.denom,
                amount: Math.floor(parseFloat(price.amount.replace(/,/g, '')) * Math.pow(10, 6)).toFixed(0),
              },
            });
          }
          if(has_dollar && has_native) {
            throw new Error('Only one price type (native / Dollar) is allowed per stage!');
          }
        }

        presale_stages.push({
          name: stage.name,
          whitelist: stage.whitelisted.filter((item: any) => item.address !== ''),
          total_supply: Math.floor(parseInt(stage.supply.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0),
          remaining_supply: Math.floor(parseInt(stage.supply.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0),
          prices: stage_prices,
          start_time: (new Date(stage.startTime).getTime() * 1000000).toFixed(0),
          end_time: (new Date(stage.endTime).getTime() * 1000000).toFixed(0),
          vesting: stage.vesting && values.vesting ? stage.vesting.map((item: any) => { return {...item, share: Math.floor(parseFloat(item.share) * 100)}; }).filter((item: any) => item.share > 0) : null,
        });
      }
    }

    let balances = [];
    for(const wallet of values.initialWallets) {
      console.log('Wallet', wallet);
      balances.push({
        address: wallet.address,
        amount: Math.floor(parseInt(wallet.amount.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0),
      });
    }

    const execMsg = new MsgExecuteContract(senderAddress, contract, {
      create_token: {
        token: {
          name: values.tokenName,
          symbol: values.symbol,
          decimals: values.decimals,

          settings: {
            mintable: values.mintable,
            initial_balances: balances,

            staking: values.staking ? {
              contract: null,
              slots: staking_slots,
              initial_deposit: Math.floor(parseInt(values.stakingSettings.initialDeposit.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0),
            } : null,
            vesting: values.vesting ? {
              contract: null,
              slots: vesting_slots,
              initial_deposit: Math.floor(parseInt(values.vestingSettings.initialDeposit.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0),
            } : null,
            presale: values.presale ? {
              contract: null,
              stages: presale_stages,

              owner: senderAddress,
              fund_receivers: values.presaleSettings.fundRecipients.map((item: any) => { return {"address": item.address, "share": Math.floor(parseFloat(item.share) * 100)} }),
              cw20_receivers: values.presaleSettings.cw20Recipients.map((item: any) => { return {"address": item.address, "share": Math.floor(parseFloat(item.share) * 100)} }),
              cw20_carry_unsold: values.presaleSettings.carryOver ? true : false,
              cw20_unsold_token_receivers: values.presaleSettings.unsoldTokensRecipient ? {"address": values.presaleSettings.unsoldTokensRecipient, "share": 10000} : null,

              admin: contract,
              wallet_limit: values.presaleSettings.walletLimit ? Math.floor(parseInt(values.presaleSettings.walletLimit.replace(/,/g, '')) * Math.pow(10, values.decimals)).toFixed(0) : null,
            } : null,

            auto_migrate: true,
            immutable: false,
          }
        },
        deposit_lock_period: 365
      }
    }, funds);

    console.log('createToken ', execMsg);

    return this.executeTransaction([execMsg], funds);
  }

  presaleBuy = async (contract: string, amount: number, lunaAmount: number): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    const funds = new Coins([new Coin('uluna', Math.floor(lunaAmount * Math.pow(10, 6)).toFixed(0))])

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
    console.log('presaleBuy ', contract, senderAddress);

    const execMsg = new MsgExecuteContract(senderAddress, contract, {
      buy: {
        amount: amount ? Math.floor(amount * Math.pow(10, 6)).toFixed(0) : null,
      }
    }, funds);

    console.log('presaleBuy ', execMsg);

    return this.executeTransaction([execMsg], funds);
  }

  presaleFinish = async (contract: string): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
    console.log('presaleFinish ', contract, senderAddress);

    const execMsg = new MsgExecuteContract(senderAddress, contract, {
      finish: {}
    });

    console.log('presaleFinish ', execMsg);

    return this.executeTransaction([execMsg], new Coins());
  }

  vestingClaim = async (contract: string): Promise<any> => {
    if(!this.wallet) {
      alert('Please connect your wallet first!');
      return;
    }

    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
    console.log('vestingClaim ', contract, senderAddress);

    const execMsg = new MsgExecuteContract(senderAddress, contract, {
      claim: {}
    });

    console.log('vestingClaim ', execMsg);

    return this.executeTransaction([execMsg], new Coins());
  }
}