import { TextField } from "@mui/material";
import { Field, useField, useFormikContext } from "formik";
import { NumericFormat } from "react-number-format";

function FormikTextField(props) {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    if(props.type === 'number') {
        return (
            <>
            <Field
                {...field} 
                {...props} 
              >
              {({ field }) => (
                  <NumericFormat
                      {...field}
                      thousandSeparator=","
                      decimalScale={(props.decimalScale) ? props.decimalScale : 0}
                      fixedDecimalScale
                      allowNegative={field.inputProps?.min < 0 ? true : false}
                      prefix=""
                      error={meta.touched && Boolean(meta.error)} 
                      helperText={meta.touched ? meta.error : ''}      
                      customInput={TextField}
                      aria-label={props.label}
                      label={props.label}
                      min={props.min ? props.min : 0}
                      max={props.max ? props.max : undefined}
                      onValueChange={(values) => {
                        setFieldValue(field.name, values.value ? values.value : 0, true);
                      }}
                  />
              )}
            </Field>
            </>
        );
    }

    return (
        <>
        <TextField 
            {...field} 
            {...props} 
            error={meta.touched && Boolean(meta.error)} 
            helperText={meta.touched ? meta.error : ''}
        />
        </>
    );
}

export default FormikTextField;