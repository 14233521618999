import { IconButton, Grid, TextField } from "@mui/material";
import { useFormikContext } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormikTextField from "./FormikTextField";
import MKTypography from "components/MKTypography";

const RecipientsList = ({ fieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  if (!values.presale) {
    return null;
  }

  return (
    <>
      {(fieldName === 'fundRecipients') ? (
        <MKTypography variant="body2" color="secondary">
          Here you can set the wallets that should receive the funds users pay during the presale. You can add as many recipients as you want. At least one recipient is necessary. The sum of all shares must not exceed 100%.
        </MKTypography>
      ) : (
        <MKTypography variant="body2" color="secondary">
        Here you can set the wallets that should receive a share of the issued CW20 tokens during the presale. You should <strong>NOT</strong> have a total share for all wallets that exceeds 10% of the total supply. You can add as many recipients as you want.
      </MKTypography>
      )}
      {values.presaleSettings[fieldName].map((item, index) => (
        <Grid container spacing={3} key={index}>
          <Grid item xs={6} md={8}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.presaleSettings[`${fieldName}[${index}].address`]}
              name={`presaleSettings.${fieldName}[${index}].address`}
              label="Recipient Address"
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values.presaleSettings[`${fieldName}[${index}].share`]}
              name={`presaleSettings.${fieldName}[${index}].share`}
              label="Percentage"
              type="number"
              decimalScale={2}
              min={0}
              max={100}
              inputProps={{ min: 0, max: 100, step: 0.01, inputMode: 'decimal' }}
            />
          </Grid>
          <Grid item xs={2} md={1}>
            <IconButton onClick={() => {
              const list = [...values.presaleSettings[fieldName]];
              list.splice(index, 1);
              setFieldValue('presaleSettings.' + fieldName, list);
            }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => {
          setFieldValue('presaleSettings.' + fieldName, [...values.presaleSettings[fieldName], { address: '', percentage: '' }]);
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </>
  );
}

export default RecipientsList;
