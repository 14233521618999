import { createContext, useContext, useEffect, useState } from 'react';
import { UserDenied, createLCDClient, useConnectedWallet } from '@terra-money/wallet-provider';
import ErrorMessage from '../components/Layers/ErrorMessage';
import BroadcastLayer from '../components/Layers/BroadcastLayer';
import { ChainClient } from 'clients/ChainClient';

const ChainContext = createContext({
    chainClients: {},
    setChainClients: () => {},
    chainId: '',
    setChainId: () => {},
    denomData: {},
    setDenomData: () => {},

    // Broadcasting
    isBroadcasting: false,
    setBroadcasting: () => {},
    setModalText: () => {},

    // Errors
    setErrorTitle: () => {},
    setErrorText: () => {},
    handleError: () => {},

  });

export const ChainProvider = ({ children }) => {
    const [chainClients, setChainClients] = useState({});
    const [chainId, setChainId] = useState('');
    const [denomData, setDenomData] = useState({});

    // Broadcasting
    const [isBroadcasting, setBroadcasting] = useState(false);
    const [modalText, setModalText] = useState('');

    // Errors
    const [errorTitle, setErrorTitle] = useState('');
    const [errorText, setErrorText] = useState('');
    const [openErrorMessage, setOpenErrorMessage] = useState(false);

    // other data

    const connectedWallet = useConnectedWallet();

    const handleOpenError = () => setOpenErrorMessage(true);
    const handleCloseError = () => setOpenErrorMessage(false);

    const handleError = (error, title) => {
        console.log(typeof error, error);
        
        if(!title) {
          title = 'Error';
        }

        if(error instanceof UserDenied) {
          setErrorTitle("Transaction denied");
          setErrorText("The transaction was denied by the user.");
        } else {
          if(typeof error === 'object') {
            if(error.response && error.response.data && error.response.data.code) {
              switch(error.response.data.code) {
                /*case 3:
                  setErrorTitle("Insufficient Funds");
                  setErrorText("You do not have enough funds to perform this transaction. Please also make sure you have enough funds in your wallet to cover the transaction fee.");
                  break;*/
                case 5:
                  setErrorTitle("Wallet not found");
                  setErrorText("The connected wallet could not be found. Please be aware that a wallet only really exists once it has made its first transaction.");
                  break;
                default:
                  if(error.toString() === '[UserDenied]' || error.toString() === '[WebExtensionUserDenied]') {
                    setErrorTitle("Transaction denied");
                    setErrorText("The transaction was denied by the user.");
                  } else if(error.response.data.message.includes('Minting is no longer possible')) {
                    setErrorTitle("Minting ended");
                    setErrorText("Minting for this NFT is no longer possible.");
                  } else if(error.response.data.message.includes('This token is not staked:')) {
                    setErrorTitle("Not staked");
                    setErrorText("This token is not staked at the moment. You need to stake it first.");
                  } else if(error.response.data.message.includes('Boost limit per tx reached.:')) {
                    setErrorTitle("Go slow!");
                    setErrorText(`You tried to ${collection.boostLabel ? collection.boostLabel.toLowerCase() : 'boost'} too many times at once. Please try again with a lower amount.`);
                  } else if(error.response.data.message.includes('Boost limit exceeded.:')) {
                    setErrorTitle("That doesn't work!");
                    setErrorText(`This NFT is already at the maximum level. You cannot ${collection.boostLabel ? collection.boostLabel.toLowerCase() : 'boost'} anymore.`);
                  } else if(error.response.data.message.includes('This stage of the presale is not active:')) {
                    setErrorTitle("That doesn't work!");
                    setErrorText(`This presale stage is not active at the moment.`);
                  } else if(error.response.data.message.includes('account sequence mismatch, expected ')) {
                    const matches = error.response.data.message.match(/account sequence mismatch, expected ([0-9]+), got ([0-9]+):/);
                    if(matches && matches.length > 2) {
                      setErrorTitle("Other transaction(s) pending");
                      setErrorText(`Your account sequence is wrong. Expected ${matches[1]}, got ${matches[2]}. That means that one or more transactions for this wallet are already pending for the current block. Please try again in a few seconds.`);
                    } else {
                      setErrorTitle(title);
                      setErrorText(error.response.data.message);
                    }
                  } else {
                    setErrorTitle(title);
                    setErrorText(error.response.data.message);
                  }
              }
            } else if(error.toString() === '[UserDenied]' || error.toString() === '[WebExtensionUserDenied]') {
              setErrorTitle("Transaction denied");
              setErrorText("The transaction was denied by the user.");
            } else {
              setErrorTitle(title);
              setErrorText(error.toString());
            }
          } else {
            setErrorTitle(title);
            setErrorText(error);
          }
        }
        
        handleOpenError();
      }

    useEffect(() => {
        let newChainClients = {};
        newChainClients['columbus-5'] = new ChainClient(createLCDClient({
            lcdClientConfig: {
                isClassic: true
            }, 
            network: {
                name: 'Terra Classic',
                chainID: 'columbus-5',
                lcd: 'https://terra-classic-lcd.publicnode.com'
            }
        }), connectedWallet, {
            fcdUrl: 'https://terra-classic-fcd.publicnode.com',
            isClassic: true
        });

        newChainClients['rebel-2'] = new ChainClient(createLCDClient({
            lcdClientConfig: {
                isClassic: true
            },
            network: {
                name: 'Terra Classic Testnet',
                chainID: 'rebel-2',
                lcd: 'https://lcd.luncblaze.com'
            }
        }), connectedWallet, {
            fcdUrl: 'https://terra-classic-fcd.publicnode.com',
            isClassic: true
        });
    
        setChainId('rebel-2');
        setChainClients(newChainClients);
    }, [connectedWallet]);

    return (
        <ChainContext.Provider value={{
            chainClients, 
            setChainClients, 
            chainId, 
            setChainId, 
            denomData, 
            setDenomData,
            isBroadcasting, 
            setBroadcasting, 
            setModalText, 
            setErrorTitle, 
            setErrorText, 
            handleError,
        }}>
            <BroadcastLayer title={"Broadcasting…"} text={modalText} openModal={isBroadcasting} />
            <ErrorMessage title={errorTitle} text={errorText} openModal={openErrorMessage} handleClose={handleCloseError} />
            {children}
        </ChainContext.Provider>
    );
};

export const useChainContext = () => {
    return useContext(ChainContext);
};
