import { IconButton, Grid, TextField } from "@mui/material";
import { useFormikContext } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FormikTextField from "./FormikTextField";

const WalletsList = ({ fieldName }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      {values[fieldName].map((item, index) => (
        <Grid container spacing={3} key={index}>
          <Grid item xs={6} md={8}>
            <FormikTextField 
              variant="standard"
              fullWidth
              value={values[`${fieldName}[${index}].address`]}
              name={`${fieldName}[${index}].address`}
              label="Wallet Address"
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <FormikTextField
              name={`${fieldName}[${index}].amount`}
              variant="standard"
              type="number"
              inputProps={{ min: 0, step: 1, inputMode: 'numeric', pattern: '[0-9]*' }}
              fullWidth
              value={values[`${fieldName}[${index}].amount`]}
              label="Amount"
            />
          </Grid>
          <Grid item xs={2} md={1}>
            <IconButton onClick={() => {
              const list = [...values[fieldName]];
              list.splice(index, 1);
              setFieldValue(fieldName, list);
            }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => {
          setFieldValue(fieldName, [...values[fieldName], { address: '', amount: '' }]);
        }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </>
  );
}

export default WalletsList;
