import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import styles from "../../assets/styles/layers.css";
import MKButton from "components/MKButton";

//import anime from "animejs";

const ErrorMessage = ({ title, text, darkMode, openModal, handleClose, isMobile }) => {

  return (
    <Modal
        sx={{ zIndex: 99000 }}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            bgcolor={darkMode ? "#171c26" : "#fff2f8"}
            className={
              !isMobile ? 'modalStyleWeb': 'modalStyleMobile'
            }
          >
            <Typography
              className={'itemDetailsModalTitle'}
              color="secondary"
              variant="h6"
              component="div"
            >
              <Typography variant="h6" component="span" mt={-0.2}>
                {title}
              </Typography>
            </Typography>
            
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              minHeight={100}
              >
            <Typography
              // This is global styles
              className={
                !isMobile
                  ? 'readMoreModalText'
                  : 'readMoreModalTextMobile'
              }
              variant="body2"
              component="p"
              color="secondary"
              lineHeight={2}
              pr={2.5}
            >
              {text}
            </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              >
              <MKButton onClick={handleClose} color="primary" variant="contained" size="medium" sx={{ mt: 2}}>
                Ok
              </MKButton>
              </Box>
          </Box>
        </Fade>
      </Modal>
  );
};

export default ErrorMessage;
